<template>
  <div class="main" data-title="客户跟踪" v-title>
    <van-dropdown-menu active-color="#1989fa" class="top-menu">
      <van-dropdown-item
        v-model="listType"
        :options="type"
        :title="dropMenuTitle + '(' + total + ')'"
        @change="changeType"
      />
      <search-customer @setSearchKey="setSearchKey"></search-customer>
    </van-dropdown-menu>

    <van-list
      class="list"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
      v-model="loading"
    >
      <van-cell
        :key="index"
        @click="showActionFun(item.client_id)"
        v-for="(item, index) in listData"
      >
        <div class="cell-wrapper">
          <div class="cell-main">
            <div class="items">
              <div class="item">
                <div class="label">客户姓名:</div>
                <div class="val">{{ item.realname }}</div>
              </div>
              <div class="item">
                <div class="label">联系手机:</div>
                <div class="val">{{ item.mobile }}</div>
              </div>
            </div>
            <div class="items">
              <div class="item">
                <div class="label">进店时间:</div>
                <div class="val">{{ item.income_date }}</div>
              </div>
              <div class="item">
                <div class="label">销售顾问d:</div>
                <div class="val">{{ getUserName(item.seller_uid) }}</div>
              </div>
            </div>
            <div class="items">
              <div class="item">
                <div class="label">跟踪次数:</div>
                <div class="val">{{ item.trace_count }}</div>
              </div>
              <div class="item">
                <div class="label">跟踪日期:</div>
                <div class="val">
                  {{
                    item.last_trace_date == null ? '无' : item.last_trace_date
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-cell>
    </van-list>
    <van-action-sheet
      :actions="actions"
      v-model="showAction"
      @cancel="showAction = false"
      @clickOverlay="showAction = false"
      @select="onSelect"
      cancel-text="取消"
    />
  </div>
</template>
<script>
import SearchCustomer from '@/components/customer/SearchCustomer.vue'
import { dateFormat } from '@/unit/unit.js'
import wx from 'weixin-js-sdk'
export default {
  components: {
    SearchCustomer,
  },
  data() {
    return {
      dropMenuTitle: '所有客户',
      type: [
        { text: '所有客户', value: 'list' },
        { text: '跟踪客户', value: 'continue' },
        { text: '重点客户', value: 'vip' },
        { text: '战败客户', value: 'failed' },
        { text: '交车客户', value: 'succeed' },
        { text: '今日回访', value: 'today' },
      ],
      listType: 'list',
      total: 0,
      client_id: '',
      showAction: false,
      actions: [
        {
          name: '马上跟踪',
          type: 'trace',
        },
        {
          name: '跟踪记录',
          type: 'record',
        },
      ],

      seachKey: {
        page: 1,
        count: 15,
      },
      listData: [],
      loading: false,
      finished: false,
    }
  },
  mounted() {
    if(this.$route.query.type){
      this.changeType(this.$route.query.type)
      this.listType = this.$route.query.type
    }
    this.getListData()
  },
  methods: {
    changeType(event) {
      console.log(event)
      if (event == 'list') {
        delete this.seachKey.is_vip
        delete this.seachKey.client_level
        delete this.seachKey.next_trace_date_end
      }
      if (event == 'continue') {
        delete this.seachKey.is_vip
        this.seachKey.client_level = -1
        delete this.seachKey.next_trace_date_end
      }
      if (event == 'vip') {
        this.seachKey.is_vip = 1
        delete this.seachKey.client_level
        delete this.seachKey.next_trace_date_end
      }

      if (event == 'failed') {
        delete this.seachKey.is_vip
        this.seachKey.client_level = 22
        delete this.seachKey.next_trace_date_end
      }
      if (event == 'succeed') {
        delete this.seachKey.is_vip
        this.seachKey.client_level = 184
        delete this.seachKey.next_trace_date_end
      }
      if (event == 'today') {
        delete this.seachKey.is_vip
        this.seachKey.client_level = -1
        this.seachKey.next_trace_date_end = dateFormat()
      }
      this.listData = []
      this.seachKey.page = 1
      this.loading = false
      this.finished = false
      this.getListData()
    },

    getListData() {
      this.seachKey.order_by = 1
      this.$axios.post('/client/lists', this.seachKey).then((res) => {
        res.data.data.list.map((item) => {
          this.listData.push(item)
        })

        this.total = res.data.data.total
        this.loading = false

        // 数据全部加载完成
        if (this.listData.length >= res.data.data.total) {
          this.finished = true
        }
      })
    },
    getUserName(uid){
      return this.$store.state.userList[uid][0]
    },
    showActionFun(client_id) {
      this.client_id = client_id
      this.showAction = true
    },
    onLoad() {
      setTimeout(() => {
        this.getListData()
        this.seachKey.page++
      }, 500)
    },
    setSearchKey(seachKey) {
      this.seachKey = seachKey
      this.listData = []
      this.loading = false
      this.finished = false
      this.getListData()
    },
    onSelect(event) {
      this.showAction = false
      if (event.type == 'trace') {
        this.$router.push({name: 'customer-tracking', query: {client_id: this.client_id}})
      }

      if (event.type == 'record') {
        this.$router.push({name: 'customer-record', query: {client_id: this.client_id}})
      }
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .van-dropdown-item__content{
  max-height: 100%;
}
.main{
  min-height: 100vh;
}
.top-menu {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
}
.list {
  margin-top: 48px;
  position: relative;
  z-index: 1;
}
.cell-wrapper {
  display: flex;
  flex-direction: row;
  .cell-main {
    flex: 1;
    .items {
      display: flex;
      align-items: center;
      .item {
        flex: 1;
        display: flex;
        .label {
          padding: 3px 8px 3px 0;
          color: #606266;
        }
        .val {
          padding: 3px 0;
          font-weight: 500;
        }
      }
    }
  }
}
</style>