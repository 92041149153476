<template>
  <van-dropdown-item title="筛选" ref="menuItem">
    <field-select
      :columns="sellArr()"
      :placeholder="'请选择'"
      isLink
      label="销售顾问"
      v-model="seachKey.seller_uid"
    ></field-select>

    <van-field
      label="客户姓名"
      placeholder="请输入"
      v-model="seachKey.realname"
    />
    <van-field
      label="手机电话"
      placeholder="请输入"
      v-model="seachKey.mobile"
    />
    <range-calendar
      :maxDate="maxDate"
      :minDate="minDate"
      isLink
      label="进店日期"
      placeholder="请选择"
      v-model="seachKey.income_date_begin_end"
    />
    <field-select
      :columns="cfgListArr('channel')"
      isLink
      label="线索渠道"
      placeholder="请选择"
      v-model="seachKey.clue_source"
    ></field-select>
    <field-select
      :columns="cfgListArr('buyTimes')"
      :label="'购车类型'"
      isLink
      placeholder="请选择"
      v-model="seachKey.buy_type"
    ></field-select>
    <field-select
      :columns="cfgListArr('customerLevel')"
      :label="'客户级别'"
      isLink
      placeholder="请选择"
      v-model="seachKey.client_level"
    ></field-select>
    <field-select
      :columns="selectArr"
      :label="'邀约到店'"
      isLink
      placeholder="请选择"
      v-model="seachKey.is_invite_income"
    ></field-select>
    <field-select
      :columns="selectArr"
      :label="'客户有效性'"
      isLink
      placeholder="请选择"
      v-model="seachKey.has_effective"
    ></field-select>
    <range-calendar
      :maxDate="maxDate"
      :minDate="minDate"
      @change="setBeginEnd($event)"
      isLink
      label="下次回访日期"
      placeholder="请选择"
      v-model="next_trace_date"
    />
    <range-calendar
      :maxDate="maxDate"
      :minDate="minDate"
      @change="setInviteDate($event)"
      isLink
      label="邀约日期"
      placeholder="请选择"
      v-model="invite_data"
    />
    <range-calendar
      :maxDate="maxDate"
      :minDate="minDate"
      @change="setTraceDate($event)"
      isLink
      label="跟踪日期"
      placeholder="请选择"
      v-model="trace_data"
    />
    <field-select
      :columns="selectArr"
      :label="'是否检核'"
      isLink
      placeholder="请选择"
      v-model="seachKey.has_check"
    ></field-select>
    <range-calendar
      :maxDate="maxDate"
      :minDate="minDate"
      @change="setCheckDate($event)"
      isLink
      label="检核日期"
      placeholder="请选择"
      v-model="check_date"
    />
    <div class="btns">
      <van-button @click="handleSearch" block type="info">确定</van-button>
      <van-button @click="resetSearch" block type="default">重置</van-button>
    </div>
  </van-dropdown-item>
</template>
<script>
import FieldSelect from '@/components/FieldSelect.vue'
import RangeCalendar from '@/components/RangeCalendar.vue'
import { sellList, dateFormat, getTimeInterval, cfgList } from '@/unit/unit.js'
export default {
  components: {
    FieldSelect,
    RangeCalendar,
  },
  data() {
    return {
      selectArr: [
        { text: '(空)', value: '' },
        { text: '是', value: 1 },
        { text: '否', value: 2 },
      ],
      minDate: new Date('2020-01-01'),
      maxDate: new Date(),
      seachKey: {
        page: 1,
        count: 15,
      },
      next_trace_date: '',
      invite_data: '',
      trace_data: '',
      check_date: '',
    }
  },
  mounted(){
    console.log('这里')
  },
  methods: {
    sellArr() {
      return sellList()
    },
    cfgListArr(type) {
      return cfgList(type)
    },
    handleSearch() {
      for (var propName in this.seachKey) {
        if (this.seachKey[propName] === '') {
          delete this.seachKey[propName]
        }
      }
      this.seachKey.seller_uid = parseInt(this.seachKey.seller_uid)
      this.$emit('setSearchKey', this.seachKey)
      this.$refs.menuItem.toggle()
    },
    resetSearch() {
      this.car_type = ''
      this.income_date_begin_end = []
      this.next_trace_date = []
      this.invite_data = []
      this.trace_data = []
      this.check_date = []
      this.book_date = []
      this.deliver_date = []
      this.fall_date = []
      this.seachKey = {
        page: 1,
        count: 15,
      }
      this.$emit('setSearchKey', this.seachKey)
    },
    setBeginEnd(event) {
      if (this.next_trace_date) {
        this.seachKey.next_trace_date_begin = event.split('/')[0]
        this.seachKey.next_trace_date_end = event.split('/')[1]
      } else {
        this.seachKey.next_trace_date_begin = ''
        this.seachKey.next_trace_date_end = ''
      }
    },
    setInviteDate(event) {
      if (this.invite_data) {
        this.seachKey.invite_income_date_begin = event.split('/')[0]
        this.seachKey.invite_income_date_end = event.split('/')[1]
      } else {
        this.seachKey.invite_income_date_begin = ''
        this.seachKey.invite_income_date_end = ''
      }
    },
    setTraceDate(event) {
      if (this.trace_data) {
        this.seachKey.trace_date_begin = event.split('/')[0]
        this.seachKey.trace_date_end = event.split('/')[1]
      } else {
        this.seachKey.trace_date_begin = ''
        this.seachKey.trace_date_end = ''
      }
    },
    setCheckDate(event) {
      if (this.check_date) {
        this.seachKey.check_date_begin = event.split('/')[0]
        this.seachKey.check_date_end = event.split('/')[1]
      } else {
        this.seachKey.check_date_begin = ''
        this.seachKey.check_date_end = ''
      }
    },
  },
}
</script>
<style lang="less" scoped>
.btns {
  display: flex;
  van-button {
    flex: 1;
  }
}
</style>